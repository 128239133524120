import { ft } from '~/utils/feature-translate';

export const loginRu = {
  login: 'Вход',
  submitButton: 'Войти',
  forgetButton: 'Забыли пароль?',
  fields: {
    login: {
      placeholder: '',
      label: 'Логин',
    },
    password: {
      placeholder: '',
      label: 'Пароль',
    },
  },
  changePass: {
    title: 'Восстановление пароля',
    submitBtn: 'Сменить пароль',
    form: {
      email: 'Адрес электронной почты',
    },
  },
};

export const t = ft(loginRu, 'login.');
