import { ApiError } from 'magner';
import { request } from '~/api/request';

export interface InitResponse {}

const initialData: InitResponse = {};

export const init = {
  data: { ...initialData },
  async set (): Promise<boolean> {
    const res = await request.api.get<{ data: InitResponse }>('/init');
    if (res.error) {
      this.data = { ...initialData };
      return (res.error instanceof ApiError && res.error.status === 401);
    }

    this.data = res.data?.data;
    return false;
  },
  get () {
    return this.data;
  },
};
