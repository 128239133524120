import { ft } from '~/utils/feature-translate';

export const TaskRu = {
  table: {
    title: 'Задачи',
    not_found: 'Нет данных',
    headers: {
      question: 'Вопрос',
    },
    tabs: {
      theory: 'Теории',
      test: 'Тесты',
      task: 'Задачи',
    },
  },
  form: {
    title: 'Создание / Редактирование задачи',
    create_new_button: 'Создать задачу',
    fields: {
      title: {
        title: 'Заголовок',
        placeholder: 'Заголовок',
      },
      type: {
        title: 'Тип',
        placeholder: 'Выберите тип',
      },
      isCaseSensitive: 'Важен регистер',
      contestQA: {
        image: 'Изображение',
        question: {
          title: 'Вопрос',
          placeholder: 'Введите вопрос',
        },
        answer: {
          title: 'Ответ',
          placeholder: 'Введите ответ',
        },
      },
      contestMissedWord: {
        image: 'Изображение',
        textBeforeWord: {
          title: 'Текст до пропущенного слова',
          placeholder: 'Введите слово',
        },
        answer: {
          title: 'Ответ',
          placeholder: 'Введите ответ',
        },
        textAfterWord: {
          title: 'Текст после пропущенного слова',
          placeholder: 'Введите слово',
        },
      },
      contestImageText: {
        image: 'Изображение',
        answer: {
          title: 'Ответ',
          placeholder: 'Введите ответ',
        },
      },
      contestPGF1: {
        title: {
          title: 'Условие задачи',
          placeholder: 'Введите условие задачи',
        },
      },
      contestPennet: {
        title: {
          title: 'Условие задачи',
          placeholder: 'Введите условие задачи',
        },
      },
    },
  },
};

export const t = ft(TaskRu, 'task.');
