import { ft } from '~/utils/feature-translate';

export const TheoryContentRu = {
  form: {
    title: 'Редактировать контент теории',
    image: 'Изображение',
    content: 'Текст',
  },
};

export const t = ft(TheoryContentRu, 'theory_content.');
