import { request } from '~/api/request';
import type { BlockEditEntity, BlockEntity, TableEntity } from './types';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { ListResponse } from '~/types';

const BASE_URL = '/term';

export const read = request.card<TableEntity, TableEntity>(async ({ api }) => {
  const res = await api.get<ListResponse<Array<BlockEntity>>>(BASE_URL);
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const cardSortUpdate = request.card<BlockEntity[], BlockEntity[]>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.post<{ data: BlockEntity[] }>(`${BASE_URL}/sort`, { sortedData: [...data.data] });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const cardEditGet = request.card<BlockEntity, BlockEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.get<{ data: BlockEntity }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});

export const cardEditCreate = request.card<BlockEntity, BlockEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  }
) => {
  filterRequestStrings(data.data);

  const res = await api.post<{ data: BlockEntity }>(`${BASE_URL}/create`,  { ...data.data });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'dictionary' });

  return { data: res.data?.data };
});

export const cardEditUpdate = request.card<BlockEntity, BlockEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string

  filterRequestStrings(data.data);

  const res = await api.patch<{ data: BlockEntity }>(`${BASE_URL}/update/${id}`,  { ...data.data });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'dictionary' });

  return { data: res.data?.data };
});

export const cardEditDelete = request.card(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string
  const res = await api.delete(`${BASE_URL}/${id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'dictionary' });

  return { data: res.data?.data };
});