import { cardPageController } from 'magner';
import { TableEntity } from './types';
import { t } from './ru';
import { read, cardEditUpdate } from './requests';
import CustomTable from './components/custom-table/index.vue';
import { baseT } from 'configs/translation/base';

export const cardConfig = cardPageController<TableEntity>({
  header: {
    title: t('table.title'),
  },

  getRequest: read,
  // @ts-ignore
  createRequest: cardEditUpdate,
  // @ts-ignore
  updateRequest: cardEditUpdate,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'content-new', params: { id: 'new' } }),
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'items',
                  dataType: 'array',
                  component: () => CustomTable,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
