import { request } from '~/api/request';
import type { TaskEditEntity, TaskEntity, TaskTableEntity } from './types';
import type { BlockRowEntity } from '~/features/content-row/types';
import type { BlockEntity } from '~/features/content/types';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { ListResponse } from '~/types';

const BASE_URL = '/task';
const ROW_URL = '/row';
const BLOCK_URL = '/block'; 

const types = [
  { type: 'missed_word', value: 'contestMissedWord' },
  { type: 'qa', value: 'contestQA' },
  { type: 'image_text', value: 'contestImageText' },
  { type: 'pgf1', value: 'contestPGF1' },
  { type: 'pgf1_sop', value: 'contestPGF1' },
  { type: 'pennet', value: 'contestPennet' },
];

// eslint-disable-next-line consistent-return
const prepareForType = (value: TaskEditEntity) => {
  const type = types.find((item) => item.type === value.type);
  const result: any = {};

  let props: string[] = [];

  if (!type) {
    return;
  }
  
  if (value.type === 'qa') {
    // contestQA_prop
    props = [
      'question', 
      'isCaseSensitive', 
      'answers', 
      'image', 
      'imageId',
    ];
  }

  if (value.type === 'missed_word') {
    // contestMissedWord_prop
    props = [
      'textBeforeWord', 
      'textAfterWord', 
      'answer', 
      'isCaseSensitive', 
      'image', 
      'imageId',
    ];
  }

  if (value.type === 'image_text') {
    // contestImageText_prop
    props = [
      'answer', 
      'image', 
      'imageId',
    ];
  }

  if (value.type === 'pgf1' || value.type === 'pgf1_sop') {
    const defaultValue = {
      parent1: {
        title: '',
        isHide: false,
        gametes: [],
      },
      parent2: {
        title: '',
        isHide: false,
        gametes: [],
      },
      combinations: [],
    };

    // eslint-disable-next-line consistent-return
    return { 
      contestPGF1_title: (value as any)[type.value]?.title,
      contestPGF1: (value as any)[type.value] || defaultValue,
    };
  }

  if (value.type === 'pennet') {
    const defaultValue = {
      vertical: Array.from(Array(4), () => ({ gamete: '', isHide: false })),
      horizontal: Array.from(Array(4), () => ({ gamete: '', isHide: false })),
      combinations: Array.from(Array(4), () => ({
        row: Array.from(Array(4), () => ({ combination: '', isHide: false })),
      })),
    };
    // eslint-disable-next-line consistent-return
    return { 
      contestPennet_title: (value as any)[type.value]?.title,
      contestPennet: (value as any)[type.value] || defaultValue,
    };
  }

  props.forEach((item: string) => {
    result[`${type.value}_${item}`] = (value as any)[type.value]?.[item];
  });

  // eslint-disable-next-line consistent-return
  return result;
};

const prepareSendValue = (type: string, value: any) => {
  if (type === 'pgf1' || type === 'pgf1_sop') {
    return { 
      contestPGF1: {
        ...value.contestPGF1,
        title: value.contestPGF1_title,
      },
    };
  }

  if (type === 'pennet') {
    return { 
      contestPennet: {
        ...value.contestPennet,
        title: value.contestPennet_title,
      },
    };
  }

  const result: any = {};

  const parentName = types.find((item) => item.type === type)?.value || '';

  result[parentName] = {};

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in value) {
    if (!key.includes(parentName)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    
    const name = key.split('_')[1];

    if (name === 'image' && value[key]) {
      if (Array.isArray(value[key])) {
        result[parentName][name] = value[key][0];
      }
      else {
        result[parentName][name] = value[`${key.split('_')[0]}_imageId`];
      }

      // eslint-disable-next-line no-continue
      continue;
    }

    result[parentName][name] = value[key];
  }

  return result;
};

export const read = request.card<TaskTableEntity, TaskTableEntity>(async ({ api, router }) => {
  // eslint-disable-next-line max-len, vue/max-len
  const res = await api.get<ListResponse<Array<TaskEntity>>>(`${BASE_URL}?sort[0][id]=sort&sort[0][value]=ASC&filters[0][id]=rowId&filters[0][value]=${router.currentRoute.value.params.rowId}`);

  const row = await api.get<{ data: BlockRowEntity }>(`${ROW_URL}/read/${router.currentRoute.value.params.rowId}`);

  const block = await api.get<{ data: BlockEntity }>(`${BLOCK_URL}/read/${router.currentRoute.value.params.blockId}`);

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');

    const name = row.data?.data?.name;

    const blockName = block?.data?.data?.name;

    if (header && name) {
      header.innerHTML = `${blockName} "${name}"`;
    }
  });
  
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const cardSortUpdate = request.card<TaskEntity[], TaskEntity[]>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.post<{ data: TaskEntity[] }>(`${BASE_URL}/sort`, { sortedData: [...data.data] });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const cardEditGet = request.card<TaskEditEntity, TaskEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.get<{ data: TaskEditEntity }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const prepareResult: TaskEditEntity = {
    ...prepareForType(res.data.data),
    title: res.data.data.title,
    type: res.data.data.type,
  };

  return {
    data: prepareResult,
  };
});

export const cardEditCreate = request.card<TaskEntity, TaskEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const rowId = router.currentRoute.value.params.rowId;

  const res = await api.post<{ data: TaskEntity }>(`${BASE_URL}/create`, { ...data.data, rowId });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'task', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditUpdate = request.card<TaskEntity, TaskEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;
  const rowId = router.currentRoute.value.params.rowId;

  filterRequestStrings(data.data);

  const sendValue = { title: data.data.title, ...prepareSendValue(data.data.type, data.data) };

  if (data.data.type === 'qa' && !sendValue?.contestQA?.answers?.length) {
    return { error: parseError({ name: '', message: 'Заполните минимум 1 ответ' }) };
  }

  const res = await api.patch<{ data: TaskEntity }>(`${BASE_URL}/update/${id}`, { ...sendValue, rowId });

  if (res.error) {
    if (data.data.type === 'pgf1' || data.data.type === 'pennet' || data.data.type === 'pgf1_sop') {
      let message = (res as any).error?.data?.errors?.[0]?.message;
      const field = (res as any).error?.data?.errors?.[0]?.field;
      let fieldName = '';

      if ((data.data.type === 'pgf1' || data.data.type === 'pgf1_sop') && field === 'gamete') {
        fieldName = 'Признак: ';
        message = message.toLowerCase();
      }

      if ((data.data.type === 'pgf1' || data.data.type === 'pgf1_sop') && field === 'title') {
        fieldName = 'Родитель: ';
        message = message.toLowerCase();
      }

      return { error: parseError({ name: '', message: `${fieldName}${message}` }) };
    }

    return { error: parseError(res.error) };
  }

  await router.push({ name: 'task', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditDelete = request.card(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;
  const res = await api.delete(`${BASE_URL}/${id}`);
  const rowId = router.currentRoute.value.params.rowId;

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'task', params: { id: rowId } });

  return { data: res.data?.data };
});
