import { routerController, translate } from 'magner';
import loginPageConfig from 'features/login/login';

import { dictionaryCardEditConfig, dictionaryCardTableConfig } from 'features/dictionary';

import { contentCardEditConfig, contentCardTableConfig } from 'features/content';

import { contentRowCardTableConfig, contentRowCardEditConfig } from 'features/content-row';

import { theoryCardTableConfig, theoryCardEditConfig } from 'features/theory';

import { theoryContentCardEditConfig } from 'features/theory-content';

import { testCardTableConfig, testCardEditConfig } from '~/features/test';

import { taskCardTableConfig, taskCardEditConfig } from '~/features/task';

import { achievementCardTableConfig, achievementCardEditConfig } from '~/features/achievement';

import { ROLE } from '~/constants';

const router = routerController<ROLE>({
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'content',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('./empty.vue'),
        roles: false,
      },
    },
    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/icons/logo.svg'),
          headerCollapsedIcon: () => import('assets/icons/logo.svg'),
          sidebarGroups: [],
        },

        routes: [
          /** CONTENT */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: contentCardTableConfig,
            },
            route: {
              name: 'content',
              path: '/content',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              icon: () => import('assets/icons/book-open.svg'),
              title: translate('base.sidebar.content_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: contentCardEditConfig,
            },
            route: {
              name: 'content-new',
              path: '/content/:id',
              link: '/content/new',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: contentRowCardTableConfig,
            },
            route: {
              name: 'content-rows',
              path: '/content/:id/rows',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: contentRowCardEditConfig,
            },
            route: {
              name: 'content-rows-new',
              path: '/content/:blockId/rows/:id',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: theoryCardTableConfig,
            },
            route: {
              name: 'theory',
              path: '/content/:blockId/rows/:rowId/theory',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: theoryCardEditConfig,
            },
            route: {
              name: 'theory-new',
              path: '/content/:blockId/rows/:rowId/theory/:id',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: theoryContentCardEditConfig,
            },
            route: {
              name: 'theory-content',
              path: '/content/:blockId/rows/:rowId/theory/:theoryId/content',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: testCardTableConfig,
            },
            route: {
              name: 'test',
              path: '/content/:blockId/rows/:rowId/test',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: testCardEditConfig,
            },
            route: {
              name: 'test-new',
              path: '/content/:blockId/rows/:rowId/test/:id',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: taskCardTableConfig,
            },
            route: {
              name: 'task',
              path: '/content/:blockId/rows/:rowId/task',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: taskCardEditConfig,
            },
            route: {
              name: 'task-new',
              path: '/content/:blockId/rows/:rowId/task/:id',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          /** DICTIONARY */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: dictionaryCardTableConfig,
            },
            route: {
              name: 'dictionary',
              path: '/dictionary',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              icon: () => import('assets/icons/dictionary.svg'),
              title: translate('base.sidebar.dictionary_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: dictionaryCardEditConfig,
            },
            route: {
              name: 'dictionary-new',
              path: '/dictionary/:id',
              link: '/dictionary/new',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: achievementCardTableConfig,
            },
            route: {
              name: 'achievement',
              path: '/achievement',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              icon: () => import('assets/icons/achievement.svg'),
              visible: true,
              title: translate('base.sidebar.achievement'),
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: achievementCardEditConfig,
            },
            route: {
              name: 'achievement-edit',
              path: '/achievement/:id',
              roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
        ],
      },
    },
    {
      type: 'preset',
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
      route: {
        path: '/login',
        name: 'login',
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
});

export default router;
