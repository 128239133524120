import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { t } from './ru';
import { cardEditCreate, cardEditUpdate, cardEditDelete, cardEditGet } from './requests';
import { TaskEditEntity } from './types';
import { saveImage } from '~/api/file-request';
import Pgf1 from './components/pgf1/index.vue';
import Pennet from './components/pennet/index.vue';

const types = [
  { label: 'Задача с пропущенным словом', value: 'missed_word' },
  { label: 'Вопрос – ответ', value: 'qa' },
  { label: 'Картинка – ответ', value: 'image_text' },
  { label: 'P/G/F1', value: 'pgf1' },
  { label: 'P/G/F1 для самост. составления и заполнения', value: 'pgf1_sop' },
  { label: 'Решетка Пеннета', value: 'pennet' },
];

export const cardConfig = cardPageController<TaskEditEntity>({
  header: {
    title: t('form.title'),
  },

  getRequest: cardEditGet,
  createRequest: cardEditCreate,
  updateRequest: cardEditUpdate,
  deleteRequest: cardEditDelete,

  confirmDelete: 'Вы уверенны, что хотите удалить задачу?',

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'task', params: { route } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 'title',
              label: t('form.fields.title.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.title.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'select',
              name: 'type',
              options: types,
              label: t('form.fields.type.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.type.placeholder'),
                readOnly: (val) => !val.isNew,
                readOnlyFormatter: (val) => types?.find((item) => item.value === val)?.label as string,
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },

            // вопрос-ответ
            {
              type: 'dropzone',
              name: 'contestQA_image',
              label: t('form.fields.contestQA.image'),
              props: {
                removable: true,
                required: false,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                hidden: (val) => val.isNew || val.state.type !== 'qa',
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
            },
            {
              type: 'input',
              name: 'contestQA_imageId',
              label: '',
              dataType: 'string',
              props: {
                hidden: true,
              },
            },
            {
              type: 'switch',
              name: 'contestQA_isCaseSensitive',
              dataType: 'boolean',
              label: t('form.fields.isCaseSensitive'),
              props: {
                hidden: (val) => val.isNew || val.state.type !== 'qa',
              },
            },
            {
              type: 'editor',
              name: 'contestQA_question',
              label: t('form.fields.contestQA.question.title'),
              dataType: 'string',
              props: {
                id: 'question-title',
                required: true,
                tools: {},
                hidden: (val) => val.isNew || val.state.type !== 'qa',
              },
              validation: [{
                type: 'empty-required-editor' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'collection',
              name: 'contestQA_answers',
              dataType: 'array',
              props: {
                showFirst: true,
                firstRemovable: true,
                hidden: (val) => val.isNew || val.state.type !== 'qa',
              },
              layout: [
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    styles: { 'align-items': 'center', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'answer',
                      label: t('form.fields.contestQA.answer.title'),
                      dataType: 'string',
                      props: {
                        label: t('form.fields.contestQA.answer.placeholder'),
                        required: true,
                        tools: {},
                      },
                      validation: [{
                        type: 'empty-required' as 'empty',
                        trigger: 'blur',
                      }],
                    } as any,
                  ],
                },
              ],
            },

            // пропущенное слово
            {
              type: 'dropzone',
              name: 'contestMissedWord_image',
              label: t('form.fields.contestMissedWord.image'),
              props: {
                removable: true,
                required: false,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                hidden: (val) => val.isNew || val.state.type !== 'missed_word',
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
            },
            {
              type: 'input',
              name: 'contestMissedWord_imageId',
              label: '',
              dataType: 'string',
              props: {
                hidden: true,
              },
            },
            {
              type: 'switch',
              name: 'contestMissedWord_isCaseSensitive',
              dataType: 'boolean',
              label: t('form.fields.isCaseSensitive'),
              props: {
                hidden: (val) => val.isNew || val.state.type !== 'missed_word',
              },
            },
            {
              type: 'editor',
              name: 'contestMissedWord_textBeforeWord',
              label: t('form.fields.contestMissedWord.textBeforeWord.title'),
              dataType: 'string',
              props: {
                id: 'text-before-word-title',
                required: true,
                tools: {},
                hidden: (val) => val.isNew || val.state.type !== 'missed_word',
              },
              validation: [{
                type: 'empty-required-editor' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'input',
              name: 'contestMissedWord_answer',
              label: t('form.fields.contestMissedWord.answer.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.contestMissedWord.answer.title'),
                hidden: (val) => val.isNew || val.state.type !== 'missed_word',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'editor',
              name: 'contestMissedWord_textAfterWord',
              label: t('form.fields.contestMissedWord.textAfterWord.title'),
              dataType: 'string',
              props: {
                id: 'text-after-word-title',
                required: true,
                tools: {},
                hidden: (val) => val.isNew || val.state.type !== 'missed_word',
              },
              validation: [{
                type: 'empty-required-editor' as 'empty',
                trigger: 'blur',
              }],
            },

            // картинка - ответ
            {
              type: 'dropzone',
              name: 'contestImageText_image',
              label: t('form.fields.contestImageText.image'),
              props: {
                removable: true,
                required: true,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                hidden: (val) => val.isNew || val.state.type !== 'image_text',
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'input',
              name: 'contestImageText_answer',
              label: t('form.fields.contestImageText.answer.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.contestImageText.answer.placeholder'),
                hidden: (val) => val.isNew || val.state.type !== 'image_text',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'input',
              name: 'contestImageText_imageId',
              label: '',
              dataType: 'string',
              props: {
                hidden: true,
              },
            },

            // pgf1
            {
              type: 'editor',
              name: 'contestPGF1_title',
              label: t('form.fields.contestPGF1.title.title'),
              dataType: 'string',
              props: {
                id: 'contestPGF1_title',
                required: true,
                tools: {},
                hidden: (val) => val.isNew || !(val.state.type === 'pgf1' || val.state.type === 'pgf1_sop'),
              },
              validation: [{
                type: 'empty-required-editor' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'custom',
              name: 'contestPGF1',
              dataType: 'object',
              component: () => Pgf1,
              props: {
                hidden: (val) => val.isNew || val.state.type !== 'pgf1',
                isSop: false,
              },
            },
            {
              type: 'custom',
              name: 'contestPGF1',
              dataType: 'object',
              component: () => Pgf1,
              props: {
                hidden: (val) => val.isNew || val.state.type !== 'pgf1_sop',
                isSop: true,
              },
            },

            // pennet
            {
              type: 'editor',
              name: 'contestPennet_title',
              label: t('form.fields.contestPennet.title.title'),
              dataType: 'string',
              props: {
                id: 'contestPennet_title',
                required: true,
                tools: {},
                hidden: (val) => val.isNew || val.state.type !== 'pennet',
              },
              validation: [{
                type: 'empty-required-editor' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'custom',
              name: 'contestPennet',
              dataType: 'object',
              component: () => Pennet,
              props: {
                hidden: (val) => val.isNew || val.state.type !== 'pennet',
              },
            },
          ],
        },
      ],
    },
  },
});
