import { ft } from '~/utils/feature-translate';

export const DictionaryRu = {
  table: {
    title: 'Словарь',
  },
  form: {
    title: 'Создание / Редактирование',
    fields: {
      name: {
        title: 'Название',
        placeholder: 'Введите название',
      },
      definition: {
        title: 'Определение',
        placeholder: 'Введите определение',
      },
    },
  },
};

export const t = ft(DictionaryRu, 'dictionary.');
