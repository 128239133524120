import { request } from '~/api/request';

const API_URL = import.meta.env.VITE_APP_API_URL;

interface FileUploadResponse {
  id: string,
  imagePath: string,
}

type Context = 'image' | 'default' | 'userAvatar' | 'product_image';

export const fileUpload = (file: File, context: Context) => {
  const fileCreate = request.custom<FileUploadResponse, File>(async ({ api, data }) => await api
    .instance
    .url(`${API_URL}v1/file/upload`, true)
    .formData({
      file: data,
      context,
    })
    .post()
    .unauthorized(() => window.location.replace('/login'))
    .json()
    .then((response) => {
      const uploaded = (response as { data: { id: string } })?.data;
      return uploaded?.id ? { data: uploaded } : { error: 'Не удалось загрузить файл' };
    })
    .catch(() => ({
      error: 'Не удалось загрузить файл',
    })) as any);

  return fileCreate(file);
};

export const saveImage = request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, 'image'));

export const saveImageContext = (context: Context) => request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, context));
