import { cardPageController } from 'magner';
import { TheoryTableEntity } from './types';
import { t } from './ru';
import { read, cardEditUpdate } from './requests';
import CustomTable from './components/custom-table/index.vue';
import { baseT } from 'configs/translation/base';

export const cardConfig = cardPageController<TheoryTableEntity>({
  header: {
    title: 'Content row',
    tabs: [
      {
        label: t('table.tabs.theory'),
        link: { name: 'theory' },
        active: true,
      },
      {
        label: t('table.tabs.test'),
        link: { name: 'test' },
        active: false,
      },
      {
        label: t('table.tabs.task'),
        link: { name: 'task' },
        active: false,
      },
    ],
  },

  getRequest: read,
  // @ts-ignore
  createRequest: cardEditUpdate,
  // @ts-ignore
  updateRequest: cardEditUpdate,

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'content-rows', params: { id: route.params.blockId } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'link',
        to: (route) => ({ name: 'theory-new', params: { blockId: route.params.blockId, rowId: route.params.rowId, id: 'new' } }),
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'items',
                  dataType: 'array',
                  component: () => CustomTable,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
