<template>
  <div class="theory-content-item">
    <div v-if="props.modelValue.isNew && !type" class="theory-content-item__types">
      <MagnerFormRadio
        v-model="type"
        :field="{
          props: {},
          options: [{
            label: 'Текст',
            value: 'text',
          }, {
            label: 'Изображение',
            value: 'image',
          }],
        }"
        @update:model-value="value.type = $event;"
      />

      <el-button
        plain
        type="danger"
        class="custom-table__btn theory-content-item__remove"
        @click="remove"
      >
        <icon-delete />
      </el-button>
    </div>

    <div v-else>
      <div class="theory-content-item__actions">
        <el-button
          v-if="!value.isNew"
          class="custom-table__btn"
          :class="{ 'custom-table__btn--disabled': index === 0 }"
          :disabled="index === 0"
          @click.prevent="emit('update-sort-up', value)"
        >
          <icon-up />
        </el-button>

        <el-button
          v-if="!value.isNew"
          class="custom-table__btn"
          :class="{ 'custom-table__btn--disabled': isLast }"
          :disabled="isLast"
          @click.prevent="emit('update-sort-down', value)"
        >
          <icon-down />
        </el-button>
      </div>

      <div class="theory-content-item__form">
        <MagnerFormEditor
          v-if="type === 'text'"
          :model-value="value.content"
          :field="fields.editor"
          @update:model-value="value.content = $event; value.isChanged = true; update()"
        />

        <MagnerFormDropzone
          v-if="type === 'image'" 
          :model-value="value.image" 
          :field="fields.image" 
          @update:model-value="value.image = $event; value.isChanged = true; update()"
        />

        <el-button
          plain
          type="danger"
          class="custom-table__btn theory-content-item__form-delete"
          @click="remove"
        >
          <icon-delete />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref, computed, defineProps, defineEmits, 
} from 'vue';

import {
  MagnerFormRadio,
  MagnerFormDropzone,
  MagnerFormEditor,
} from 'magner';

import IconUp from '~/components/icon-up.vue';
import IconDown from '~/components/icon-down.vue';
import IconDelete from '~/components/icon-delete.vue';

import { saveImage } from '~/api/file-request';

import type { TheoryContentEditEntity } from '../types';
  
interface Props {
  modelValue: TheoryContentEditEntity,
  index: number,
  isLast: boolean,
}

const emit = defineEmits(['update:modelValue', 'update-sort-up', 'update-sort-down', 'remove']);

const props = defineProps<Props>();

const type = ref(props.modelValue.type || '');
const value = ref(props.modelValue);

const fields = {
  image: {
    type: 'dropzone',
    name: 'image',
    label: 'Изображение',
    props: {
      removable: true,
      valueKey: 'id',
      srcKey: 'downloadUrl',
      saveToBackend: saveImage,
      inputAtts: {
        accept: 'image/png, image/jpeg, image/webp',
      },
    },
  },
  editor: {
    name: 'name',
    label: 'Текст',
    dataType: 'string',
    props: {
      id: `name_${props.index}`,
      required: true,
      tools: {},
    },
  },
};

const update = () => {
  emit('update:modelValue', value.value);
};

const remove = () => {
  emit('remove', props.index);
};
</script>
