<template>
  <el-table :data="list" class="data-table">
    <el-table-column label="Название">
      <template #default="{row}">
        <router-link :to="toLink(row.id)" class="cell-content">{{ row.name }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Цвет">
      <template #default="{row}">
        <router-link :to="toLink(row.id)" class="cell-content">
          <div :style="{'background-color': row.color}" class="custom-table__color" />
        </router-link>
      </template>
    </el-table-column>

    <el-table-column alight-right>
      <template #default="{row}">
        <router-link :to="toLink(row.id)" class="cell-content">
          <div class="custom-table__actions">
            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === 0 }"
              @click.prevent="rowUp(row, cardSortUpdate)"
            >
              <icon-up />
            </el-button>

            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === list.length - 1 }"
              @click.prevent="rowDown(row, cardSortUpdate)"
            >
              <icon-down />
            </el-button>

            <router-link :to="toLinkEdit(row.id)" class="custom-table__btn">
              <el-button class="custom-table__edit" />
            </router-link>
          </div>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';

import { cardSortUpdate } from '~/features/content/requests';

import { sortTableRequest } from '~/api/sort-table-request';

import IconUp from '~/components/icon-up.vue';
import IconDown from '~/components/icon-down.vue';

import './style.css';

const props = defineProps({
  modelValue: {
    type: Array as any,
    required: true,
  },
});

const { list, setupSort, findIndex, rowUp, rowDown } = sortTableRequest(props.modelValue);

const toLink = (id: string) => `/content/${id}/rows`;

const toLinkEdit = (id: string) => `/content/${id}`;

setupSort(props.modelValue);
</script>
