import { request } from '~/api/request';
import { init } from '~/utils/get-init';

const profileRequest = request.profile(async ({ api, lstorage }) => {
  const token = lstorage.read('token');

  if (!token) return { error: { message: 'Пользователь не авторизован', fields: {} } };

  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const unauthorized = await init.set();

  if (unauthorized) {
    lstorage.delete('token');

    // TODO добавить в типизацию
    lstorage.delete('refreshToken');
    lstorage.delete('role');
    lstorage.delete('user');
    api.instance.defaults({ headers: {} }, false);

    return { error: { message: 'Пользователь не авторизован', fields: {} } };
  }

  // TODO добавить в типизацию
  const user = lstorage.read('user');
  const role = lstorage.read('role') as string;

  return {
    data: {
      user,
      role,
    },
  };
});

export default profileRequest;
