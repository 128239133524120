import { request } from '~/api/request';
import type { AchievementTableEntity, AchievementEntity, AchievementEditEntity } from './types';
import { DataListResponse } from '~/types';

const BASE_URL = '/achievement/view';

export const read = request.card<AchievementTableEntity, AchievementTableEntity>(async ({ api }) => {
  const res = await api.get<DataListResponse<Array<AchievementEntity>>>(BASE_URL);
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data.data,
    },
  };
});

export const cardEditGet = request.card<AchievementEntity, AchievementEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.get<{ data: AchievementEntity }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});

export const cardEditUpdate = request.card<AchievementEntity, AchievementEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;

  const sendValue = {
    ...data.data,
  };

  if (sendValue.image) {
    sendValue.image = sendValue.image[0];
  }

  const res = await api.patch<{ data: AchievementEntity }>(`${BASE_URL}/update/${id}`, { ...sendValue });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'achievement' });

  return { data: res.data?.data };
});    

export const cardEditCreate = () => {};
