<template>
  <div class="pennet">
    <div class="pennet__block">
      <div class="pennet__header">
        <div class="pennet__square"></div>
  
        <div class="pennet__row">
          <div 
            v-for="item, i in model.horizontal"
            :key="i"
            class="pennet__item"
          >
            <pennet-item 
              v-model="item.gamete"
              v-model:is-hide="item.isHide"
            />
          </div>
        </div>
      </div>
  
      <div class="pennet__content">
        <div class="pennet__aside">
          <div 
            v-for="item, i in model.vertical"
            :key="i"
            class="pennet__item"
          >
            <pennet-item 
              v-model="item.gamete"
              v-model:is-hide="item.isHide"
            />
          </div>
        </div>
  
        <div class="pennet__body">
          <div 
            v-for="item, i in model.combinations"
            :key="i"
            class="pennet__row"
          >
            <div 
              v-for="row, rowIndex in item.row"
              :key="rowIndex"
              class="pennet__item"
            >
              <pennet-item 
                v-model="row.combination" 
                v-model:is-hide="row.isHide"
                combination
                :disabled="isDisabledCombination" 
                :combination-header="combinationHeader(i, rowIndex)" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pennet-message">{{ message }}</div>
</template>

<script lang="ts" setup>
import { ref, defineProps, computed } from 'vue';

import type { ContestPennet } from '~/features/task/types';

import PennetItem from './Item.vue';

import './style.css';

interface Props {
  modelValue: ContestPennet,
}

const props = defineProps<Props>(); 

const model = ref(props.modelValue);

const isDisabledCombination = computed(() => {
  const result = [...model.value.horizontal, ...model.value.vertical].filter((item) => item.gamete?.length === 2);

  return result.length !== 8;
});

const isErrorCombination = computed(() => {
  const result = model.value.combinations.map((item) => item.row).flat()
    .find((item) => item.combination?.length !== 4);

  return result;
});

const message = computed(() => {
  if (isDisabledCombination.value) {
    return '* Все заголовки должны быть заполнены и состоять из 2х символов';
  }

  if (isErrorCombination.value) {
    return '* Все комбинации должны быть заполнены и состоять из 4х символов';
  }

  return '';
});

const combinationHeader = (index: number, rowIndex: number) => ({
  horizontal: model.value.horizontal[rowIndex],
  vertical: model.value.vertical[index],
});
</script>
