<template>
  <el-card class="theory-content width-half">
    <div>
      <div
        v-for="(item, index) in rows"
        :key="item.id"
        class="theory-content__item"
      >
        <theory-content-item 
          v-model="rows[index]" 
          :index="+index" 
          :is-last="isLast(item)"
          @update:modelValue="update"
          @update-sort-up="rowUp($event, cardSortUpdate, updateTheoryContentAll);"
          @update-sort-down="rowDown($event, cardSortUpdate, updateTheoryContentAll);"
          @remove="removeItem" 
        />
      </div>
    </div>

    <el-button 
      type="primary" 
      plain 
      class="mt-2"
      @click="addBlock"
    >
      Добавить
    </el-button>
  </el-card>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, watch } from 'vue';

import { removeTheoryContent, cardSortUpdate, updateTheoryContentAll } from '~/features/theory-content/requests';

import { sortTableRequest } from '~/api/sort-table-request';

import type { TheoryContentEditEntity } from '~/features/theory-content/types';

import TheoryContentItem from './Item.vue';

import './style.css';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Array as any,
    required: true,
  },
});

const { 
  rows, 
  rowUp, 
  rowDown,
} = sortTableRequest(props.modelValue);

const isLast = (item: TheoryContentEditEntity) => {
  const updatedItems = rows.value.filter((i) => !i.isNew);

  const findIndex = updatedItems.findIndex((i) => i.id === item.id);

  return updatedItems.length - 1 === findIndex;
};

const update = () => {
  emit('update:modelValue', rows.value);
};

const addBlock = async () => {
  const value: any = {
    id: rows.value.length + 1,
    content: '',
    image: null,
    isNew: true,
    type: '',
    sort: rows.value.length + 1,
  };

  rows.value.push(value);

  update();
};

const removeItem = async (index: number) => {
  const item = rows.value[index];

  rows.value.splice(index, 1);

  if (item.isNew) {
    return;
  }

  await removeTheoryContent({ data: { id: item.id }, isNew: false, id: item.id as string });
};

watch(rows, update);
</script>
