import { cardPageController } from 'magner';
import { t } from './ru';
import { read, cardEditUpdate } from './requests';
import { TheoryContentTableEntity } from './types';
import { baseT } from 'configs/translation/base';
import CustomItems from './custom-items/index.vue';

export const cardConfig = cardPageController<TheoryContentTableEntity>({
  header: {
    title: t('form.title'),
  },

  getRequest: read,
  
  // @ts-ignore
  createRequest: cardEditUpdate,
  // @ts-ignore
  updateRequest: cardEditUpdate,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'theory', params: { route } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'items',
                  dataType: 'array',
                  component: () => CustomItems,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
