import { cardPageController } from 'magner';
import { AchievementTableEntity } from './types';
import { t } from './ru';
import { read, cardEditCreate, cardEditUpdate } from './requests';
import CustomTable from './components/custom-table/index.vue';

export const cardConfig = cardPageController<AchievementTableEntity>({
  header: {
    title: t('table.title'),
  },
   
  getRequest: read,
  // @ts-ignore
  // eslint-disable-next-line no-undef
  createRequest: cardEditCreate,
  // @ts-ignore
  // eslint-disable-next-line no-undef
  updateRequest: cardEditUpdate,

  form: {
    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'items',
                  dataType: 'array',
                  component: () => CustomTable,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
