<template>
  <el-table :data="props.modelValue" class="data-table">
    <el-table-column label="Заголовок">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">{{ row.title }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Описание">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">{{ row.description }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Тип">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">{{ row.type }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Ширина">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">{{ row.width }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Сортировка">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">{{ row.sort }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Изображение">
      <template #default="{row}">
        <router-link :to="toLinkEdit(row.id)" class="cell-content">
          <div class="cell-content_images">
            <div class="el-image cell-content_images_image">
              <img 
                v-if="row.image" 
                :src="row.image" 
                class="el-image__inner"
                style="object-fit: cover;"
              >
            </div>
          </div>
        </router-link>
      </template>
    </el-table-column>

    <el-table-column alight-right>
      <template #default="{row}">
        <div class="custom-table__actions">
          <router-link :to="toLinkEdit(row.id)" class="custom-table__btn">
            <el-button class="custom-table__edit" />
          </router-link>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
  
<script lang="ts" setup>
import { defineProps } from 'vue';

import type { AchievementEntity } from '../../types';

import './style.css';

interface Props {
  modelValue: AchievementEntity,
}

const props = defineProps<Props>();

const toLinkEdit = (id: string) => `/achievement/${id}`;
</script>
