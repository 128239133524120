import { request } from '~/api/request';
import type { TestEditEntity, TestEntity, TestTableEntity, TestAnswerEntity} from './types';
import type { BlockRowEntity } from '~/features/content-row/types';
import type { BlockEntity } from '~/features/content/types';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { ListResponse } from '~/types';

const BASE_URL = '/test';
const ROW_URL = '/row';
const BLOCK_URL = '/block';

const validateAnswers = (answers: TestAnswerEntity[]) => {
  if (!answers || !answers.length) {
    return 'Заполните минимум 1 ответ';
  }

  const result = answers.find((item) => item.isCorrect);

  if (!result) {
    return 'Выберите правильный ответ';
  }

  return '';
};

export const read = request.card<TestTableEntity, TestTableEntity>(async ({ api, router }) => {
  // eslint-disable-next-line max-len, vue/max-len
  const res = await api.get<ListResponse<Array<TestEntity>>>(`${BASE_URL}?sort[0][id]=sort&sort[0][value]=ASC&filters[0][id]=rowId&filters[0][value]=${router.currentRoute.value.params.rowId}`);

  const row = await api.get<{ data: BlockRowEntity }>(`${ROW_URL}/read/${router.currentRoute.value.params.rowId}`);

  const block = await api.get<{ data: BlockEntity }>(`${BLOCK_URL}/read/${router.currentRoute.value.params.blockId}`);

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');

    const name = row.data?.data?.name;

    const blockName = block?.data?.data?.name;

    if (header && name) {
      header.innerHTML = `${blockName} "${name}"`;
    }
  });
  
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const cardSortUpdate = request.card<TestEntity[], TestEntity[]>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.post<{ data: TestEntity[] }>(`${BASE_URL}/sort`, { sortedData: [...data.data] });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const cardEditGet = request.card<TestEntity, TestEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.get<{ data: TestEntity }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});

export const cardEditCreate = request.card<TestEntity, TestEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const rowId = router.currentRoute.value.params.rowId;

  const sendValue = {
    ...data.data,
  };

  if (sendValue.image) {
    sendValue.image = sendValue.image[0];
  } 
  else {
    delete sendValue.image;
  }

  const res = await api.post<{ data: TestEntity }>(`${BASE_URL}/create`, { ...sendValue, rowId });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'test', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditUpdate = request.card<TestEntity, TestEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const isInvalid = validateAnswers(data.data.answers);

  if (isInvalid) {
    return { error: parseError({ name: '', message: isInvalid}) };
  }

  const id = data.id as string;
  const rowId = router.currentRoute.value.params.rowId;

  filterRequestStrings(data.data);

  const sendValue = {
    ...data.data,
  };

  if (sendValue.image) {
    sendValue.image = sendValue.image[0] || sendValue.image || null;
  }

  const resultSendValue = {
    ...sendValue,
    rowId,
  };

  const res = await api.patch<{ data: TestEntity }>(`${BASE_URL}/update/${id}`, { ...resultSendValue });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'test', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditDelete = request.card(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;
  const res = await api.delete(`${BASE_URL}/${id}`);
  const rowId = router.currentRoute.value.params.rowId;

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'test', params: { id: rowId } });

  return { data: res.data?.data };
});
