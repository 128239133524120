<template>
  <el-table :data="list" class="data-table">
    <el-table-column label="Вопрос">
      <template #default="{row}">
        <router-link :to="toLinkDetail($route.params, row.id)" class="cell-content">{{ row.question }}</router-link>
      </template>
    </el-table-column>

    <el-table-column label="Табличный вид">
      <template #default="{row}">
        <router-link 
          :to="toLinkDetail($route.params, row.id)" 
          class="cell-content"
        >
          {{ row.isTableView ? 'Да' : 'Нет' }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column label="Изображение">
      <template #default="{row}">
        <router-link :to="toLinkDetail($route.params, row.id)" class="cell-content">
          <div class="cell-content_images">
            <div class="el-image cell-content_images_image">
              <img 
                v-if="row.image" 
                :src="row.image" 
                class="el-image__inner"
                style="object-fit: cover;"
              >
            </div>
          </div>
        </router-link>
      </template>
    </el-table-column>

    <el-table-column alight-right>
      <template #default="{row}">
        <router-link :to="toLinkDetail($route.params, row.id)" class="cell-content">
          <div class="custom-table__actions">
            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === 0 }"
              @click.prevent="rowUp(row, cardSortUpdate)"
            >
              <icon-up />
            </el-button>

            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === list.length - 1 }"
              @click.prevent="rowDown(row, cardSortUpdate)"
            >
              <icon-down />
            </el-button>

            <router-link :to="toLinkEdit($route.params, row.id)" class="custom-table__btn">
              <el-button class="custom-table__edit" />
            </router-link>
          </div>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';

import { cardSortUpdate } from '~/features/test/requests';

import { sortTableRequest } from '~/api/sort-table-request';

import IconUp from '~/components/icon-up.vue';
import IconDown from '~/components/icon-down.vue';

import './style.css';

const props = defineProps({
  modelValue: {
    type: Array as any,
    required: true,
  },
});

const { list, findIndex, rowUp, rowDown } = sortTableRequest(props.modelValue);

// eslint-disable-next-line max-len, vue/max-len
const toLinkEdit = (params: { blockId?: string, rowId?: string }, id: string) => `/content/${params.blockId}/rows/${params.rowId}/test/${id}`;
// eslint-disable-next-line max-len, vue/max-len
const toLinkDetail = (params: { blockId?: string, rowId?: string }, id: string) => `/content/${params.blockId}/rows/${params.rowId}/test/${id}/`;
</script>
