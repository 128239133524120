import { baseRu } from 'configs/translation/base';
import { loginRu } from 'features/login/ru';
import { ContentRu } from 'features/content/ru';
import { ContentRowRu } from 'features/content-row/ru';
import { TheoryRu } from 'features/theory/ru';
import { TheoryContentRu } from 'features/theory-content/ru';
import { TestRu } from '~/features/test/ru';
import { TaskRu } from '~/features/task/ru';
import { AchievementRu } from '~/features/achievement/ru';
import { DictionaryRu } from '~/features/dictionary/ru';

const ruCustom = {
  base: baseRu,
  login: loginRu,

  content: ContentRu,
  content_row: ContentRowRu,

  dictionary: DictionaryRu,

  theory: TheoryRu,
  theory_content: TheoryContentRu,
  test: TestRu,
  task: TaskRu,

  achievement: AchievementRu,
};
export default ruCustom;
