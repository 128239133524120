import { ft } from '~/utils/feature-translate';

export const baseRu = {
  sidebar: {
    content_group: 'Содержание',
    achievement: 'Достижения',
    dictionary_group: 'Словарь',
  },
  form: {
    submit_button: 'Сохранить',
    cancel_button: 'Отменить',
    cancel_order_button: 'Отменить заказ',
    remove_button: 'Удалить',
    create_new_button: 'Добавить',
    start_button: 'Начать работу',
    edit_button: 'Редактировать',
    no_edit_button: 'Закончить без изменений',
    back_button: 'Назад',
    close_button: 'Закрыть',
    unpin_button: 'Открепить',
  },
  status: {
    active: 'Активный',
    inactive: 'Неактивный',
    blocked: 'Заблокирован',
    request: 'Запрос на удаление',
    removed: 'Удален',
  },
  roles: {
    admin: 'Администратор',
    user: 'Пользователь',
    manager: 'Менеджер',
  },
};

export const baseT = ft(baseRu, 'base.');
