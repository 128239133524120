import { ft } from '~/utils/feature-translate';

export const TestRu = {
  table: {
    title: 'Основные понятия',
    not_found: 'Нет данных',
    headers: {
      question: 'Вопрос',
    },
    tabs: {
      theory: 'Теории',
      test: 'Тесты',
      task: 'Задачи',
    },
  },
  form: {
    title: 'Создание / Редактирование теста',
    create_new_button: 'Создать тест',
    fields: {
      question: {
        title: 'Вопрос теста',
        placeholder: 'Введите вопрос теста',
      },
      answer: {
        name: {
          title: 'Ответ',
          placeholder: 'Введите ответ',
        },
        isCorrect: 'Верный ответ',
      },
      isTableView: {
        title: 'Табличный вид',
      },
      image: {
        title: 'Изображение',
      },
    },
  },
};

export const t = ft(TestRu, 'test.');
