import { ft } from '~/utils/feature-translate';

export const TheoryRu = {
  table: {
    title: 'Основные понятия',
    not_found: 'Нет данных',
    headers: {
      id: 'ID',
      name: 'Имя',
      image: 'Изображение',
    },
    tabs: {
      theory: 'Теории',
      test: 'Тесты',
      task: 'Задачи',
    },
  },
  form: {
    title: 'Создание / Редактирование теории',
    create_new_button: 'Создать новую главу теории',
    fields: {
      name: {
        title: 'Название',
        placeholder: 'Введите название',
      },
      image: {
        title: 'Изображение',
        placeholder: '',
      },
    },
  },
};

export const t = ft(TheoryRu, 'theory.');
