import { ft } from '~/utils/feature-translate';

export const ContentRu = {
  table: {
    title: 'Содержание',
  },
  form: {
    title: 'Создание / Редактирование блока',
    fields: {
      name: {
        title: 'Название',
        placeholder: 'Введите название'
      },
      color: {
        title: 'Цвет',
        placeholder: 'Введите цвет в HEX кодировке'
      },
    },
  },
};

export const t = ft(ContentRu, 'content.');
