import { cardPageController } from 'magner';
import { t } from './ru';
import { cardEditCreate, cardEditUpdate, cardEditDelete, cardEditGet } from './requests';
import { TestEditEntity, TestAnswerEntity } from './types';
import { baseT } from 'configs/translation/base';

import { saveImage } from '~/api/file-request';

export const cardConfig = cardPageController<TestEditEntity>({
  header: {
    title: t('form.title'),
  },

  getRequest: cardEditGet,
  createRequest: cardEditCreate,
  updateRequest: cardEditUpdate,
  deleteRequest: cardEditDelete,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'test', params: { route } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 'question',
              label: t('form.fields.question.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.question.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'dropzone',
              name: 'image',
              label: t('form.fields.image.title'),
              props: {
                removable: true,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
            },
            {
              type: 'collection',
              name: 'answers',
              dataType: 'array',
              props: {
                showFirst: true,
                firstRemovable: true,
                hidden: (val) => val.isNew,
              },
              layout: [
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    styles: { 'align-items': 'center' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'answer',
                      label: t('form.fields.answer.name.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: t('form.fields.answer.name.placeholder'),
                      },
                      validation: [{
                        type: 'empty-required' as 'empty',
                        trigger: 'input',
                      }],
                    },
                    {
                      type: 'switch',
                      name: 'isCorrect',
                      dataType: 'boolean',
                      label: t('form.fields.answer.isCorrect'),
                      props: {},
                      changeAction: (form, el: TestAnswerEntity) => setTimeout(() => {
                        form.form.answers.forEach((item: TestAnswerEntity) => {
                          item.isCorrect = false;
                        });

                        el.isCorrect = true;
                      }),
                    },
                  ],
                },
              ],
            },
            {
              type: 'switch',
              name: 'isTableView',
              dataType: 'boolean',
              label: t('form.fields.isTableView.title'),
              props: {},
            },
          ],
        },
      ],
    },
  },
});
