import { request } from '~/api/request';
import { getListResponse } from '~/utils/get-list';
import type { TheoryEditEntity, TheoryEntity, TheoryTableEntity } from './types';
import type { BlockRowEntity } from '~/features/content-row/types';
import type { BlockEntity } from '~/features/content/types';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { ListResponse } from '~/types';

const BASE_URL = '/theory';
const ROW_URL = '/row';
const BLOCK_URL = '/block';

export const read = request.card<TheoryTableEntity, TheoryTableEntity>(async ({ api, router }) => {
  // eslint-disable-next-line max-len, vue/max-len
  const res = await api.get<ListResponse<Array<TheoryEntity>>>(`${BASE_URL}?filters[0][id]=rowId&filters[0][value]=${router.currentRoute.value.params.rowId}`);

  const row = await api.get<{ data: BlockRowEntity }>(`${ROW_URL}/read/${router.currentRoute.value.params.rowId}`);

  const block = await api.get<{ data: BlockEntity }>(`${BLOCK_URL}/read/${router.currentRoute.value.params.blockId}`);

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');

    const name = row.data?.data?.name;
    const blockName = block?.data?.data?.name;

    if (header && name) {
      header.innerHTML = `${blockName} "${name}"`;
    }
  });
  
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const cardSortUpdate = request.card<TheoryEntity[], TheoryEntity[]>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.post<{ data: TheoryEntity[] }>(`${BASE_URL}/sort`, { sortedData: [...data.data] });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const cardEditGet = request.card<TheoryEntity, TheoryEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const res = await api.get<{ data: TheoryEntity }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});

export const cardEditCreate = request.card<TheoryEntity, TheoryEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const rowId = router.currentRoute.value.params.rowId;
  const sendValue = {
    ...data.data,
  };

  if (sendValue.image) {
    sendValue.image = sendValue.image[0];
  }

  filterRequestStrings(data.data);

  const res = await api.post<{ data: TheoryEntity }>(`${BASE_URL}/create`, { ...sendValue, row: rowId });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'theory', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditUpdate = request.card<TheoryEntity, TheoryEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;
  const rowId = router.currentRoute.value.params.rowId;
  const sendValue = {
    ...data.data,
  };

  if (sendValue.image) {
    sendValue.image = sendValue.image[0];
  }

  filterRequestStrings(data.data);

  const res = await api.patch<{ data: TheoryEntity }>(`${BASE_URL}/update/${id}`, { ...sendValue, row: rowId });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'theory', params: { id: rowId } });

  return { data: res.data?.data };
});

export const cardEditDelete = request.card(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const id = data.id as string;
  const res = await api.delete(`${BASE_URL}/${id}`);
  const rowId = router.currentRoute.value.params.rowId;

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'theory', params: { id: rowId } });

  return { data: res.data?.data };
});
