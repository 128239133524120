import { cardPageController } from 'magner';
import { t } from './ru';
import { cardEditCreate, cardEditUpdate, cardEditDelete, cardEditGet } from './requests';
import { SubBlockEditEntity } from './types';
import { baseT } from 'configs/translation/base';
import { saveImage } from '~/api/file-request';

export const cardConfig = cardPageController<SubBlockEditEntity>({
  header: {
    title: t('form.title'),
  },

  getRequest: cardEditGet,
  createRequest: cardEditCreate,
  updateRequest: cardEditUpdate,
  deleteRequest: cardEditDelete,

  confirmDelete: true,

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'content-rows', params: { id: route.params.blockId } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: t('form.fields.name.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.name.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'dropzone',
              name: 'image',
              label: t('form.fields.image.title'),
              props: {
                required: true,
                removable: true,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
          ],
        },
      ],
    },
  },
});
