import { request } from '~/api/request';
import { PROJECT_NAME } from '~/constants';

const logoutRequest = request.custom(async ({ api }) => {
  localStorage.removeItem(`magner-${PROJECT_NAME}`);
  api.instance.defaults({ headers: {} }, false);

  await api.delete('/auth/logout');

  return {
    data: null,
  };
});

export default logoutRequest;
