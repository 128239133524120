<template>
  <div class="pennet-item">
    <input 
      v-maska
      data-maska-tokens="#:[a-zA-z]" 
      :data-maska="combination ? '####' : '##'" 
      :value="props.modelValue" 
      :disabled="disabled" 
      :class="{ 
        'is-error': invalidCombination === 'error', 
        'is-success': invalidCombination === 'success' 
      }"
      @input="emits('update:modelValue', $event?.target?.value)"
    >
    
    <icon-view 
      v-if="props.isHide" 
      class="pennet-item__btn" 
      @click="emits('update:isHide', !props.isHide)" 
    />

    <icon-view-hide 
      v-else 
      class="pennet-item__btn" 
      @click="emits('update:isHide', !props.isHide)" 
    />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, computed } from 'vue';
import { vMaska } from 'maska';
import type { GameteEntity } from '~/features/task/types';

import IconView from '~/components/icon-view.vue';
import IconViewHide from '~/components/icon-view-hide.vue';

interface Props {
  modelValue: string,
  disabled?: boolean,
  isHide: boolean,
  combination?: boolean,
  combinationHeader?: {
    vertical: GameteEntity,
    horizontal: GameteEntity,
  },
}

const props = defineProps<Props>();

const emits = defineEmits(['update:modelValue', 'update:isHide']);

const toSortValue = (value: string) => value.toLowerCase().split('').sort().join('');

const invalidCombination = computed(() => {
  if (!props.combination) {
    return false;
  }

  const combinationHeaderValue = `
    ${props.combinationHeader?.vertical?.gamete}
    ${props.combinationHeader?.horizontal?.gamete}
  `.split(/\s+/).join('').trim();

  const headerValue = toSortValue(combinationHeaderValue);
  const value = toSortValue(props.modelValue);

  if (combinationHeaderValue.length < 4 || value.length < 4) {
    return false;
  }

  return headerValue === value ? 'success' : 'error';
});
</script>
