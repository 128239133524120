import { cardPageController } from 'magner';
import { t } from './ru';
import { cardEditCreate, cardEditUpdate, cardEditDelete, cardEditGet } from './requests';
import { BlockEditEntity } from './types';
import { baseT } from 'configs/translation/base';
import ColorPicker from '~/components/color-picker.vue';

export const cardConfig = cardPageController<BlockEditEntity>({
  header: {
    title: t('form.title'),
  },

  getRequest: cardEditGet,
  createRequest: cardEditCreate,
  updateRequest: cardEditUpdate,
  deleteRequest: cardEditDelete,

  confirmDelete: true,

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'content', params: { route } }),
        props: {
          text: baseT('form.back_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: t('form.fields.name.title'),
              dataType: 'string',
              props: {
                required: true,
                placeholder: t('form.fields.name.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },

            {
              type: 'custom',
              name: 'color',
              label: t('form.fields.color.title'),
              dataType: 'string',
              component: () => ColorPicker,
              props: {
                required: true,
              },
              validation: {
                type: 'required-hex' as 'empty',
                trigger: 'change',
              },
            },
          ],
        },
      ],
    },
  },
});
