<template>
  <el-card>
    <div class="pgf1">
      <div class="pgf1__line">
        <div class="pgf1__name">
          P
        </div>

        <div class="pgf1__items pgf1__items_parent">
          <div class="pgf1__item  pgf1__item_parent">
            <div>
              <pgf1-item 
                v-model="model.parent1.title" 
                v-model:is-hide="model.parent1.isHide" 
                parent 
                :is-sop="isSop"
                @add-gamet="addGamete('parent1')"
              />
            </div>
          </div>

          <div class="pgf1__item pgf1__item_parent">
            <div>
              <pgf1-item
                v-model="model.parent2.title" 
                v-model:is-hide="model.parent2.isHide" 
                parent  
                :is-sop="isSop"
                @add-gamet="addGamete('parent2')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pgf1__line">
        <div class="pgf1__name">
          G
        </div>

        <div class="pgf1__items pgf1__items_gamete">
          <div class="pgf1__item pgf1__item_gamete">
            <div
              v-for="item, key in model.parent1.gametes"
              :key="key"
            >
              <pgf1-item 
                v-model="item.gamete" 
                v-model:is-hide="item.isHide"
                :is-sop="isSop"
                @remove="removeGamete('parent1', key)" 
              />
            </div>
          </div>

          <div class="pgf1__item pgf1__item_gamete">
            <div
              v-for="item, key in model.parent2.gametes"
              :key="key"
            >
              <pgf1-item
                v-model="item.gamete" 
                v-model:is-hide="item.isHide"
                :is-sop="isSop"
                @remove="removeGamete('parent2', key)" 
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pgf1__line">
        <div class="pgf1__name">
          F
          <el-button circle class="pgf1__add" @click="addCombination">+</el-button>
        </div>

        <div class="pgf1__items">
          <div class="pgf1__item">
            <div
              v-for="item, key in model.combinations"
              :key="key"
            >
              <pgf1-item
                v-model="item.combination" 
                v-model:is-hide="item.isHide"
                :is-sop="isSop"
                @remove="removeCombination(key)" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>  
  </el-card>
</template>

<script lang="ts" setup>
import { ref, defineProps, computed } from 'vue';

import { ElMessage } from 'element-plus';

import type { ContestPGF1 } from '~/features/task/types';

import Pgf1Item from './Item.vue';

import './style.css';

interface Props {
  modelValue: ContestPGF1,
  field: {
    isSop: boolean,
  },
}

const props = defineProps<Props>(); 

const model = ref(props.modelValue);

const totalGametes = computed(() => model.value.parent1.gametes.length + model.value.parent2.gametes.length);

const isSop = computed(() => props.field.isSop);

const addGamete = (parentName: string) => {
  const totalGametesParent = model.value[parentName as 'parent1' | 'parent2'].gametes.length;

  if (totalGametes.value >= 8) {
    ElMessage({
      message: 'Не более 8 признаков',
      type: 'warning',
    });

    return;
  }
  
  if (totalGametesParent >= 4) {
    ElMessage({
      message: 'Не более 4х признаков у родителя',
      type: 'warning',
    });

    return;
  }

  const value = {  
    gamete: '',
    isHide: isSop.value, 
  };

  model.value[parentName as 'parent1' | 'parent2'].gametes.push(value);
};

const removeGamete = (parentName: string, index: number) => {
  model.value[parentName as 'parent1' | 'parent2'].gametes.splice(index, 1);
};

const addCombination = () => {
  if (model.value.combinations.length >= 16) {
    ElMessage({
      message: 'Не более 16 комбинаций',
      type: 'warning',
    });

    return;
  }

  const value = {  
    combination: '',
    isHide: isSop.value, 
  };

  model.value.combinations.push(value);
};

const removeCombination = (index: number) => {
  model.value.combinations.splice(index, 1);
};
</script>
