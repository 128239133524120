import { ft } from '~/utils/feature-translate';

export const ContentRowRu = {
  form: {
    title: 'Создание / Редактирование содержания',
    fields: {
      name: {
        title: 'Название',
        placeholder: 'Введите название',
      },
      image: {
        title: 'Изображение',
        placeholder: '',
      },
    },
  },
};

export const t = ft(ContentRowRu, 'content_row.');
