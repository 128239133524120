import { validationController } from 'magner';

/** Validations are functions that are used for form fields validation.
 * Here, you are configuring the object where key is a validation unique name and value is
 * a checking function.<br>
 *
 * First argument is a data you can use to check field value<br>
 * Second argument is callback. If callback argument is 'new Error("message")', the error will be shown over the field.
 */
const validation = validationController({
  'empty-required': ({ value, form }, callback) => {
    if (typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
      || (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }
    callback();
  },

  'empty-required-editor': ({ value, form }, callback) => {
    try {
      if (!JSON.parse(value).blocks.length) {
        callback(new Error('Поле обязательно к заполнению'));

        return;
      }

      callback();
    }
    catch (_) {
      callback(new Error('Поле обязательно к заполнению'));
    }
  },

  'empty-required-array': ({ value, form }, callback) => {
    if (typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }
    callback();
  },

  'empty-required-user': ({ value, form }, callback) => {
    if (typeof value === 'undefined' || value === null) {
      callback(new Error('Поля обязательны к заполнению'));
      return;
    }

    if (value.type === 'new') {
      Object.values(value.newEntity).map((item) => {
        if (typeof item === 'undefined'
          || item === null
          || (typeof item === 'string' && !item?.trim().length)
          || (Array.isArray(item) && !item.length)
        ) {
          callback(new Error('Поля обязательны к заполнению'));
        }

        return item;
      });
    }
    else if (!value.existingId || value.existingId.length === 0) {
      callback(new Error('Поля обязательны к заполнению'));
    }

    callback();
  },

  'only-text': ({ value, form }, callback) => {
    if (!/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(value)) {
      callback(new Error('Только буквы'));
    }
    callback();
  },

  'email-required': ({ rule, value }, callback) => {
    if (value && !value.match(/[^@]+@[^.]+\..+/i)) {
      callback(new Error('Неверный формат E-mail'));
    }
    else {
      callback();
    }
  },

  url: ({ rule, value }, callback) => {
    if (value && !value.match(/(https?:\/\/)[a-zа-яё0-9._-]+\.[a-zа-яё]+(\/?.*)?/i)) {
      callback(new Error('Неверный формат ссылки'));
    }
    else {
      callback();
    }
  },

  /** Example validator showing that you can access values from other fields of the form */
  passwordConfirm: ({ value, form }, callback) => {
    if (value && form.password !== value) {
      callback(new Error('Пароли не совпадают!'));
    }
    else if (!value) {
      callback(new Error('Введите повтор пароля'));
    }
    else {
      callback();
    }
  },

  /** Phone field validation */
  phone: ({ rule, value, form }, callback) => {
    if (value && !value.startsWith('+')) {
      callback(new Error('Номер должен начинаться с "+"'));
    }
    else {
      callback();
    }
  },

  'max-length-128': ({ value }, callback) => {
    if (value.trim().length > 128) {
      callback(new Error('Максимальное количество символов 128'));
    }
    else {
      callback();
    }
  },

  'min-length-12': ({ value }, callback) => {
    if (value.trim().length < 12) {
      callback(new Error('Минимальное количество символов 12'));
    }
    else {
      callback();
    }
  },

  'number-only': ({ value }, callback) => {
    if (/[^0-9.]/.test(value)) {
      callback(new Error('Значение может состоять только из цифр и точки'));
    }
    else {
      callback();
    }
  },

  'required-hex': ({ value }, callback) => {
    if (!value?.trim()) {
      callback(new Error('Поле обязательно к заполнению'));

      return;
    }
    
    if (!/^#[0-9a-f]{3,6}$/i.test(value)) {
      callback(new Error('Значение не соответствует HEX кодировке'));

      return;
    }

    callback();
  },
});

export default validation;
