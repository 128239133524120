import { request } from '~/api/request';
import { filterRequestStrings } from '~/utils/filter-request-strings';

import type { 
  TheoryContentEditEntity, 
  TheoryContentEditListEntity, 
  TheoryContentEntity, 
} from './types';
import { ListResponse } from '~/types';

const BASE_URL = '/theoryContent';

export const read = request.card(async ({ api, router }) => {
  // eslint-disable-next-line max-len, vue/max-len
  const res = await api.get<ListResponse<Array<TheoryContentEntity>>>(`${BASE_URL}?sort[0][id]=sort&sort[0][value]=ASC&filters[0][id]=theoryId&filters[0][value]=${router.currentRoute.value.params.theoryId}`);
  
  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const createTheoryContent = request.card<TheoryContentEditEntity, TheoryContentEditEntity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const theoryId = router.currentRoute.value.params.theoryId;

  const sendValue = {
    ...data.data,
    theory: theoryId,
    type: data.data.content ? 'text' : 'image',
  };

  if (Array.isArray(sendValue.image)) {
    sendValue.image = sendValue.image[0];
  }

  const res = await api.post<{ data: TheoryContentEditEntity }>(`${BASE_URL}/create`, sendValue);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const updateTheoryContent = request.card<TheoryContentEditEntity, TheoryContentEditEntity>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const sendValue: Partial<TheoryContentEditEntity> = {};

  if (data.data.content) {
    sendValue.content = data.data.content;
  }

  if (Array.isArray(sendValue.image)) {
    sendValue.image = sendValue.image[0];
  }

  filterRequestStrings(data.data);

  const res = await api.patch<{ data: TheoryContentEditEntity }>(`${BASE_URL}/update/${data.data.id}`, sendValue);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const removeTheoryContent = request.card(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const id = data.id as string;
  const res = await api.delete(`${BASE_URL}/${id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const cardSortUpdate = request.card<TheoryContentEntity[], TheoryContentEntity[]>(async (
  {
    api,
    data,
    parseError,
  },
) => {
  const value = data.data.map((item: TheoryContentEntity) => ({ id: item.id, sort: item.sort }));
  const res = await api.post<{ data: TheoryContentEntity[] }>(`${BASE_URL}/sort`, { sortedData: value });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const removeTheoryContentAll = async (items: Partial<TheoryContentEditEntity>[]) => {
  // eslint-disable-next-line max-len, vue/max-len
  const remove = items.filter((item) => !item.isNew && ((!item.image || (Array.isArray(item.image) && !item.image.length)) && !item.content));

  await Promise.all(remove.map(async (item) => {
    removeTheoryContent({ data: { id: item.id }, isNew: false, id: item.id as string });
  }));
};

export const updateTheoryContentAll = async (items: Partial<TheoryContentEditEntity>[]) => {
  const isNew = items.filter((item) => item.isNew && (item.content || item.image));
  const isUpdate = items.filter((item) => !item.isNew && item.isChanged && (item.content || item.image));

  await Promise.all(isNew.map(async (item) => {
    await createTheoryContent({ data: item as TheoryContentEditEntity, id: '', isNew: true });
  }));

  await Promise.all(isUpdate.map(async (item) => {
    await updateTheoryContent({ data: item as TheoryContentEditEntity, id: '', isNew: true });
  }));

  const result = await read({ data: {}, id: '', isNew: false });

  return result.data?.items;
};

export const cardEditUpdate = request.card<TheoryContentEditListEntity, TheoryContentEditListEntity>(async (
  {
    data,
    router,
  },
) => {
  const items = data.data.items;

  await removeTheoryContentAll(items);
  await updateTheoryContentAll(items);

  await router.push({ name: 'theory' });

  return { data: { items: [] } };
});
