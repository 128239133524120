<template>
  <el-table :data="list" class="data-table">
    <el-table-column label="Название">
      <template #default="{row}">
        <div class="cell-content">
          {{ row.name }}
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Определение">
      <template #default="{row}">
        <div class="cell-content">
          {{ row.definition }}
        </div>
      </template>
    </el-table-column>

    <el-table-column alight-right>
      <template #default="{row}">
        <div class="cell-content">
          <div class="custom-table__actions">
            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === 0 }"
              @click.prevent="rowUp(row, cardSortUpdate)"
            >
              <icon-up />
            </el-button>

            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === list.length - 1 }"
              @click.prevent="rowDown(row, cardSortUpdate)"
            >
              <icon-down />
            </el-button>

            <router-link :to="toLinkEdit(row.id)" class="custom-table__btn">
              <el-button class="custom-table__edit" />
            </router-link>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import {defineProps, PropType} from 'vue';

import { cardSortUpdate } from '~/features/dictionary/requests';

import { sortTableRequest } from '~/api/sort-table-request';

import IconUp from '~/components/icon-up.vue';
import IconDown from '~/components/icon-down.vue';

import './style.css';

import { BlockEntity } from "features/dictionary/types";

const props = defineProps({
  modelValue: {
    type: Array as PropType<BlockEntity[]>,
    required: true,
  },
});

const { list, setupSort, findIndex, rowUp, rowDown } = sortTableRequest(props.modelValue);
const toLinkEdit = (id: string) => `/dictionary/${id}`;
setupSort(props.modelValue);
</script>
