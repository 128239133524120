import { ft } from '~/utils/feature-translate';

export const AchievementRu = {
  table: {
    title: 'Достижения',
  },
  form: {
    title: 'Создание / Редактирование достижения',
    fields: {
      title: {
        title: 'Заголовок',
        placeholder: 'Введите заголовок',
      },
      type: {
        title: 'Тип',
        placeholder: 'Введите тип',
      },
      description: {
        title: 'Описание',
        placeholder: 'Введите описание',
      },
      width: {
        title: 'Ширина',
        placeholder: 'Введите ширину',
      },
      sort: {
        title: 'Сортировка',
        placeholder: 'Введите сортировку',
      },
      image: {
        title: 'Изображение',
        placeholder: '',
      },
    },
  },
};

export const t = ft(AchievementRu, 'achievement.');
