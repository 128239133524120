<template>
  <div class="custom-color-picker">
    <el-color-picker
      color-format="hex"
      size="large"
      v-model="val"
      popper-class="color-picker"
      @change="changeVal"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps, onMounted,
  ref, watch,
} from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const val = ref(props.modelValue);

const changeVal = (newVal: any) => {
  val.value = newVal;

  emit('update:modelValue', val.value);
};

watch(() => props.modelValue, () => {
  val.value = props.modelValue;
});
</script>

<style lang="postcss">
.color-picker > div {
  padding: 6px 12px;
  margin: -6px -12px;
  background-color: white;
  border-radius: var(--el-popper-border-radius);
  border: 1px solid var(--el-border-color-lighter)
}
</style>
